import React, { useState } from "react";

function ContactFormField(props) {
  return (
    <div className="form-group">
      <label htmlFor={props.htmlFor}>{props.label}</label>
      <input
        type={props.inputType}
        className="form-control"
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
      />
    </div>
  );
}

export default function Contact() {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();

  function HandleSubmit(e) {
    e.preventDefault();
    let bodyText = [name, email, message];
    console.log("Submitted: ", bodyText);
    fetch("http://localhost:3002/send", {
      method: "POST",
      body: JSON.stringify(bodyText),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          alert("Message Sent.");
          this.resetForm();
        } else if (response.status === "fail") {
          alert("Message failed to send.");
        }
      });
  }

  return (
    <div className="content-wrapper">
      <h1>Contact</h1>
      <a href="https://blog.mailtrap.io/react-contact-form/#:~:text=A%20contact%20form%20represents%20an%20opportunity%20for%20web,sent%20as%20an%20email%20to%20your%20email%20address.">
        Tutorial here
      </a>
      <br />
      <form
        id="contact-form"
        // method="POST"
      >
        <ContactFormField
          className="contact-name"
          htmlFor="name"
          label="Name"
          type="text"
          onChange={setName}
        />
        <ContactFormField
          className="contact-email"
          htmlFor="email"
          label="Email"
          type="email"
          onChange={setEmail}
        />
        <ContactFormField
          className="contact-message"
          htmlFor="message"
          label="Message"
          type="text"
          onChange={setMessage}
        />
        <button
          type="submit"
          className="btn btn-primary"
          onClick={(e) => {
            HandleSubmit(e);
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
}
