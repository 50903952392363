import React from "react";
import UNS1 from "../Images/UNS-1.png";
import UNS2 from "../Images/UNS-2.png";
import UNS3 from "../Images/UNS-3.png";
import UNS4 from "../Images/UNS-4.png";
import UNS5 from "../Images/UNS-5.png";

export default function UdsUns() {
  return (
    <div>
      <h1>User Needs Scoring for an athenahealth Reporting Platform</h1>
      <p>
        When developing B2B products, understanding your user's top jobs can be
        an excellent input to have in your product's prioritization.
      </p>
      <p>
        I worked with a scrum team on to better understand user satisfaction and
        importance of user jobs related to reporting. We utilized Anthony
        Ulwick's Jobs to Be Done (JTBD) approach to help generate a list of user
        jobs and survey questions.
      </p>
      <p>
        In this instance, I joined after a list of jobs had already been
        codified, so it was my task to help them design, build, and analyze
        survey results.
      </p>
      <p>
        Building the survey was straightforward. I spent some time with the team
        to tweak jobs to make them more clear and specific, and then I added
        them to a survey. Using the JTBD approach, each job appeared in the
        survey with the following questions:
      </p>
      <p>
        <ul>
          <li>How important is it to [job]</li>
          <li>How satisfied are you with the way you [job]</li>
        </ul>
      </p>
      <p>Below is an example of how the survey looks in Qualtrics:</p>
      <img
        className="case-study-image"
        src={UNS1}
        alt={
          "Sample UNS survey matrix. Left column with blurred-out question, next columns represent the importance likert and the satisfaction likert scales."
        }
      />
      <p>
        This survey method is beneficial because it measures user satisfaction
        with a job and the importance to users. This helps reduce the risk of
        improving a painful experience with a low overall impact.
      </p>
      <p>
        We sent the survey out to athenaNet users, and waited a few weeks to get
        a sufficient sample size.
      </p>
      <h4>Analysis</h4>
      <p>
        To assist in cleaning the data, I created a KNIME workflow to help pull
        the data from Qualtrics and prepare for analysis. KNIME is a data
        analytics tool that allows you to simplify data cleaning using less
        code. This saves me of time when pulling down new results and
        re-cleaning the data as results came in.
      </p>

      <p>The workflow essentially completed the following:</p>

      <p>
        <ul>
          <li>Read in a Excel file</li>
          <li>Remove preview responses or responses with empty data</li>
          <li>
            Transposed the table for ease of analysis (one row per job per
            respondent, rather than one row per respondent)
          </li>
          <li>Recoded variables from strings to numerical values</li>
          <li>
            Used logic to add metadata and details to row to facilitate further
            analysis
          </li>
          <li>Calculated raw scores for data exports</li>
        </ul>
      </p>
      <img
        className="case-study-image"
        src={UNS2}
        alt={
          "Screenshot of KNIME, a data analytics tool where you can drag and drop nodes in order to string together analyses."
        }
      />
      <h4>Visualizations</h4>
      <p>
        Once the data was cleaned, I created a few visualizations in Tableau to
        help us understand our top opportunities. Tableau is a BI platform used
        to create stunning visualizations with ease.
      </p>
      <p>
        The first visualization was a standard Jobs to Be Done "Opportunity
        Landscape" which plots importance and satisfaction on x and y axes,
        respectively.
      </p>
      <p>
        In the matrix below, Items towards the bottom right of the matrix were
        most critical to fix. Hovering on a dot with a mouse would show more
        details about the opportunity.
      </p>
      <img
        className="case-study-image"
        src={UNS3}
        alt={"Screenshot of the opportunity matrix"}
      />
      <p>
        While this was a useful visualization, it did not highlight the
        uncertainty or variability in our data.
      </p>
      <p>
        To help the team understand overlap between opportunities, I used a bar
        chart with 95% confidence intervals to help communicate relative
        differences between opportunities.
      </p>
      <img
        className="case-study-image"
        src={UNS4}
        alt={"Bar chart for satisfaction with 95% confidence intervals."}
      />
      <p>
        I also created a similar visualization for the "importance" scores for
        these jobs as well. Most importance scores were very similar in terms of
        job importance, except for the bottom few jobs.
      </p>
      <img
        className="case-study-image"
        src={UNS5}
        alt={"Bar chart for importannce with 95% confidence intervals."}
      />
      <h4>Outcome</h4>
      <p>
        Using these different visualizations, the team was able to better
        determine which effects were "true differences" in order to assist in
        prioritization. Rather than focus on the "top opportunity", the team was
        able to choose from a number of jobs to improve in order to better the
        reporting experience. The team also learned that many of the new
        features they were working on directly related to the top jobs
        identified in the opportunity matrix and satisfaction distribution, and
        were excited to conduct further research to validate that the features
        and improved satisfaction on these core jobs.
      </p>
      <h4>Wrap Up</h4>
      <p>
        Using Anthony Ulwick's Jobs to Be Done model is great for obtaining a
        "big picture" of the opportunities present in your users work. However,
        be sure to account for the amount of noise in your data by analyzing the
        individual metrics, and the variability that is present within them.
        Using confidence intervals, we were able to clearly see where
        opportunities truly differed, and where they were similar. This can be
        helpful for obtaining a clearer understanding of where to focus efforts
        in a large portfolio of work.
      </p>
    </div>
  );
}
