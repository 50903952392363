import React from "react";

export default function UserNPS() {
  return (
    <div>
      <h1>User NPS</h1>

      <h4>What</h4>
      <p>
        I played a key role in the establishment of User NPS as a corporate
        metric at athenahealth. I led in the reporting and analysis of results,
        and collaborated with stakeholders to learn of valuable data slices and
        inputs. Results were shared with senior leadership and the broader R&D
        organization on a monthly basis.
      </p>

      <h4>Why</h4>
      <p>
        Prior to this engagement, there was no consistent measure for overall
        user sentiment at athenahealth. Measuring user satisfaction is necessary
        to know the overall sentiment and satisfaction of our user base.
      </p>

      <h4>How</h4>
      <p>
        We launched the survey to users by presenting them with a lightbox in
        logging into athenaNet. This was completed on a monthly basis over the
        course of a year.
      </p>
      <p>
        Much of the analysis I completed was in Excel - my tool of choice at the
        time. I would user excel formulas to build the report, and then share
        with our designers who would then make a Sketch PDF with the results.
        Additionally, our team spent time coding by hand to look for key themes
        to share in the report.
      </p>
      <p>
        With time, I eventually moved the analysis process into R to speed up
        the time it takes to analyze and report out results.
      </p>
      <p>
        After building up a year worth of user NPS data, the metric was
        established as a corporate metric and was handed off to a Customer
        Intelligence team to manage and report out regularly. It was an awesome
        experience to help create a major source of customer feedback.
      </p>
      <h4>Key Contributions</h4>
      <ul>
        <li>Responsible for the monthly analysis of User NPS results.</li>
        <li>
          Collaborated on a Sketch App report where results were transferred and
          shared with leadership and the broader organization.
        </li>
        <li>
          Experimented with various question types and recruiting methods to
          learn more about what moves the needle.
        </li>
        <li>
          Developed a dataset of over 4000 data points over the course of a
          year, and helped in the establishment of User NPS as a corporate
          metric.
        </li>
      </ul>
    </div>
  );
}
