import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-grid-system";

function CaseStudyLink({ to, linkName, detail }) {
  return (
    <Link className="case-study-link" to={to} style={{ textDecoration: 'none'}}>
      <Container className='case-study-card'>
          <strong>{linkName}</strong>
        <br />
        <span className='detail-text'>{detail}</span>
      </ Container>
    </Link>
  );
}

// function CaseStudyLink({ to, linkName, detail }) {
//   return (
//     <Link to={to} className={`case-study-card ${className}`} style={{ textDecoration: 'none'}}>
//       <div>
//         <strong>{linkName}</strong>
//         <br />
//         {detail}
//       </div>
//     </Link>
//   );
// };

export default function WorkLinks() {
  return (
    <>
      <CaseStudyLink
        to={"/Work/UdsUns"}
        linkName={"Reporting User Needs Scoring at athenahealth"}
        detail={
          "I utilized survey data to help inform prioritization of user jobs using Anthony Ulwick's Jobs to Be Done approach."
        }
      />
      <CaseStudyLink
        to={"/Work/DataRec"}
        linkName={"Interoperability research at athenahealth"}
        detail={
          "I led a series of quantitative and qualitiative research efforts to understand how practice staff utilize shared information from other organizations."
        }
      />
      <CaseStudyLink
        to={"/Work/UserNPS"}
        linkName={"User NPS at athenahealth"}
        detail={
          "I played a key role in the analysis and reporting of User NPS."
        }
      />
      <CaseStudyLink
        to={"/Work/Workflows"}
        linkName={"Systematizing Expert Reviews at athenahealth"}
        detail={
          "I was the project lead of an initiative to create a reproducible design evaluation method that used to evaluate over 100 workflows in athenaNet."
        }
      />
      <CaseStudyLink
        to={"/Work/StudyCentral"}
        linkName={"Development of an Internal Request Form at athenahealth"}
        detail={
          "I worked on the design, research, and engineering of an internal application to request and track research requests."
        }
      />
      <CaseStudyLink
        to={"/Work/Fidelity"}
        linkName={"Unmoderated Usability Testing at Fidelity"}
        detail={
          "I used UserZoom to evaluate a re-design of the Fidelity news page."
        }
      />
      <CaseStudyLink
        to={"/Work/Cigna"}
        linkName={"Moderated RITE Testing at Cigna"}
        detail={
          "I worked on a usability test of Cigna.com to evaluate usability of a new design where we rapidly iterated and tested changes."
        }
      />
    </>
  );
}
