import React, { useEffect } from "react";
import WorkLinks from "./WorkLinks";

export default function Work() {

  useEffect(() => {
    document.title='Will Benson | Case Studies';
  })
  
  return (
    <div className="content-wrapper">
      <h1>Case Studies</h1>
      <WorkLinks />
    </div>
  );
}
