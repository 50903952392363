import React from "react";
import DR2 from "../Images/DR-2.png";
import DR3 from "../Images/DR-3.png";
import DR4 from "../Images/DR-4.png";
import DR5 from "../Images/DR-5.png";
import DR6 from "../Images/DR-6.png";

export default function DataRec() {
  return (
    <div>
      <h1>Data Reconciliation</h1>
      <h4>What</h4>
      <p>
        I led a series of quantitative and qualitiative research efforts to
        understand how practice staff utilize shared information from other
        practices, hosptials, etc. I used a mix of discovery interviews, site
        visits, and behavioral surveys.
      </p>
      <h4>Why</h4>
      <p>
        Shared chart infromation from providers is crucial for ensuring that
        healthcare practitioners have good situation awareness abotu their
        patients. Having a "full picture' of the patient story can increase
        quality of care, reduce cost, and increase provider efficiency. However,
        the problem space is complex Some challenges we uncovered included
        different provider preferences of what constitutes "good" information to
        have, competing vendors, inconsistent data formats, and varying quality
        of patient documentation.
      </p>
      <p>
        The objective of the research was to learn (a) how the workflow is used
        in the real world (b) whom was responsible completing this workflow at
        practices, and (c) what decisions could we automate or take away based
        on what we know about user preferences for incoming data.
      </p>
      <h4>How</h4>
      <h5>Step 1: Frame a Research Proposal</h5>
      <p>
        At the start of any research engagement, I create a research proposal to
        outline our core assumptions and beliefs about a problem space. I also
        point to prior research I can locate around the organization, and I
        begin to frame a series of research questions that my team and I begin
        to formulate together. This document is a reference I can go back to at
        any time, as well as update as we validate or refute our assumptions.
      </p>
      <h5>Step 2: Conduct foundational interviews</h5>
      <p>
        We started by using interviews to uncover user needs and goals. We
        learned about how users use the current workflow today, the challenges
        they face, and the improvements they desire from a UI perspective. We
        learned about how providers/physicians delegate this work to their
        staff, and how the provider will clean up the patient's chart afterwards
        in order to get a handle on the work.{" "}
      </p>
      <p>
        I conducted 1x1 moderated interviews over WebEx with physicians and
        clinical staff. Through research we generated insights about current
        workflows, challenges, and UI suggestions and improvements.{" "}
      </p>
      <p>
        The biggest takeaways for us were how practice staff delegate work in
        order to optimize physician workloads. The challenge we uncovered is how
        to make it so more physician workload could be automated by the system.
        Automation, trust, and keeping the provider informed are all important
        facets to consider when developing in this area.{" "}
      </p>
      <h5>Step 3: Conduct site visits</h5>
      <p>
        Ethnographic research was then required for us to get a deeper
        understanding of how a practice works with this data. We visited several
        practices with the intent of watching how providers and staff complete
        this task of data reconciliation, how this data is used in the
        encounter, and some of the challenges faced in-context with the task of
        data reconciliation. I created a large presentation at the end of this
        research engagement to summarize and document any research findings.
      </p>
      <p>Example slides of research findings below...</p>
      <img className="case-study-image" src={DR2} alt={"Findings"} />
      <img className="case-study-image" src={DR3} alt={"Findings"} />
      <img className="case-study-image" src={DR4} alt={"Findings"} />
      <h5>Step 5: Discovery interviews on designs</h5>
      <p>
        As a result of the site visits, our designers created a few design
        concepts to address some of the issues that were discussed in our
        previous rounds of research. We conducted exploratory interviews with
        end users to to learn about what worked/didn't work with each design, as
        well as evaluate basic usability of design flats. Prototypes had a very
        limited amount of interaction at this time.{" "}
      </p>
      <h5>Step 6: Logic preference survey</h5>
      <p>
        In order to reduce the burden placed on end users who need to reconcile
        hundreds of items per day, we began to explore how we might automate the
        task of “choose your chart info or their chart info.”
      </p>
      <p>
        We hypothesized that we could reduce user effort by automating certain
        decisions. Working with the scrum team, we identified a few common
        decisions users make during the task.
      </p>

      <p>
        Once we defined the decisions we could make on behalf of the user, we
        built a survey that asked users to make decisions on "staged" data
        reconciliation conflicts. Users we asked to review the image and make a
        decision as if the information was going to their chart. After each
        decision, we asked participants to rate their comfort level if we were
        to automatically pull information of that type into the chart. We
        learned that certain types of data lend themselves well towards
        automation - but other pieces of information must be reviewed, things
        that would help provide situation awareness to the physician and their
        staff prior to an encounter.
      </p>
      <p>
        Below is an example of some of the results that were shown at the
        conculusion of this study. We created a few realistic conflicts, and had
        participants "choose" what they would want to keep in the patient record
        - their chart data, incoming data, or neither. This helped us to
        determine user behavior - and if we could consider exploring automation
        for certain options over others.{" "}
      </p>
      <img className="case-study-image" src={DR5} alt={"Findings"} />
      <img className="case-study-image" src={DR6} alt={"Findings"} />
      <h4>Outcomes</h4>
      <p>
        Unfortunately, due to shifting priorities the work was put on hold. The
        work was picked up later when I was off the team, and the research
        findings were used to help the team get up to speed with how users think
        about data reconciliation and associated workflows.
      </p>
      <h4>Key Contributions</h4>
      <p>
        I led the end-to-end research plan for the data reconciliation team,
        presented to our scrum team, executive leaders, and documented research
        findings for future reference.
      </p>
      <p>
        I coordinated and scheduled site visits for various members of our scrum
        team to attend and learn more about our users.{" "}
      </p>
    </div>
  );
}
