import React, { useEffect } from "react";
import "./App.css";
import AppBar from "./AppBar.jsx";
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import PortfolioRouter from "./PortfolioRouter";
import PageHeader from "./PageHeader.jsx";
import ScrollToTop from "./ScrollToTop";
import ReactGA from 'react-ga4';

export default function App() {
  
  useEffect(() => {
    ReactGA.initialize('G-9P0N7M7GQT', { debug: false });
  }, [])

  function RouteChangeTracker() {
    const location = useLocation();
  
    useEffect(() => {
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }, [location]);
  
    return null;
  }
  
  return (
    <div className="App">
      <Router>
        <RouteChangeTracker />
        <PageHeader />
        <AppBar />
        <PortfolioRouter className="AppContent" />
        <ScrollToTop />
      </Router> 
    </div>
  );
}
