import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./Home.jsx";
import Resume from "./Resume.jsx";
import Work from "./Work.jsx";
import Contact from "./Contact.jsx";

// Case Studies
import {
  DataRecWrapped,
  WorkflowsWrapped,
  UserNPSWrapped,
  StudyCentralWrapped,
  FidelityWrapped,
  CignaWrapped,
  UdsUnsWrapped,
} from "./Work/WorkWrapper";

export default function PortfolioRouter() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/Resume">
        <Resume />
      </Route>
      <Route path="/Contact">
        <Contact />
      </Route>
      <Route exact path="/Work">
        <Work />
      </Route>
      {/* SPECIFIC CASE STUDIES */}
      {/* <Switch> */}
        <Route path="/Work/DataRec">
          <DataRecWrapped />
        </Route>
        <Route path="/Work/Workflows">
          <WorkflowsWrapped />
        </Route>
        <Route path="/Work/UserNPS">
          <UserNPSWrapped />
        </Route>
        <Route path="/Work/StudyCentral">
          <StudyCentralWrapped />
        </Route>
        <Route path="/Work/Fidelity">
          <FidelityWrapped />
        </Route>
        <Route path="/Work/Cigna">
          <CignaWrapped />
        </Route>
        <Route path="/Work/UdsUns">
          <UdsUnsWrapped />
        </Route>
      {/* </Switch> */}
    </Switch>
  );
}
