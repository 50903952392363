import React from "react";
import DQS1 from "../Images/dqs-1.png";
import DQS2 from "../Images/dqs-2.png";
import DQS3 from "../Images/dqs-3.png";
import DQS4 from "../Images/dqs-4.png";
import DQS5 from "../Images/dqs-5.png";
import DQS6 from "../Images/dqs-6.png";
import DQS7 from "../Images/dqs-7.png";

export default function DataRec() {
  return (
    <div>
      <h1>Workflows</h1>
      <h4>What</h4>
      <p>
        At athenahealth, I was the project lead of an initiative to create the
        "Design Quality Score" - a reproducible design evaluation method that
        was used to evaluate over 100 user workflows over the course of several
        years. The method was largely based off of{" "}
        <a
          href={"https://www.nngroup.com/articles/ten-usability-heuristics/"}
          alt="Neilsen Norman Group's article summarizing the 10 Usability Heuristics for User Interface Design"
          target="_blank"
          rel="noopener noreferrer"
        >
          Nielsen's 10 heuristics
        </a>
        , with the addition of a grading rubric that helped us to "quantify"
        different levels of "Design Quality."
      </p>
      <p>
        The initiative was successful in establishing "Design Quality" as a
        repeatable method and metric that product teams could use to quickly
        communicate usability improvements made to athenaNet workflows.
      </p>
      <h4>Why</h4>
      <p>
        The goal of the initiative was to provide a consistent, cost-effective,
        and repeatable metric that teams could use to qualify and quantify
        product usability.
      </p>
      <h4>How</h4>
      <p>
        We started the initiative by working with UX product leaders within
        athenahealth to identify the top user workflows for their respective
        products. Once these were identified, I worked with designers, product
        owners, and training staff to record quick 4-7 minute videos outlining
        workflows. These workflows were used to show how the workflow was
        completed.
      </p>
      <p>
        Once workflows we’re recorded, organized groups of 3-4 reviewers per
        workflow video. We would spend 2-3 hours in a room reviewing the
        workflow and calling out issues that violated one of the 10 usability
        heuristics. Once complete, we scored each heuristic on a 0-4 scale based
        on the severity of usability issues.
      </p>
      <p>
        After each heuristic was scored, we obtained a design quality score.{" "}
      </p>
      <p>
        This method was repeated for over 100 workflows in athenaNet. It gave
        teams a clear lever to move when assessing workflows. While the metric
        itself was prone to subjective bias, we learned a lot about which
        workflows were acceptable, and what an "average" or "poor" workflow
        looked like.
      </p>
      <p>
        As I ran more of these sessions over time, identified opportunities to
        increase the efficiency of the method. First, I created a survey with
        the 10 heuristics and 10 scoring questions that allowed us to score
        workflows asynchronously, rather than in a meeting. This allowed us to
        also automate the analysis and reporting, where results were directly
        funneled into a Tableau dashboard. This dramatically cut down the time
        it took to run these surveys.
      </p>
      <p>Below is an example of the welcome screen of the survey...</p>
      <img className="case-study-image" src={DQS1} alt={"Survey"} />
      <p>
        Here is an example of the textboxes that were used to enter findings...
      </p>
      <img className="case-study-image" src={DQS2} alt={"Survey"} />
      <p>
        After findings were provided, users were asked to provide a score for
        the entire heuristic.
      </p>
      <img className="case-study-image" src={DQS3} alt={"Survey"} />
      <p>
        Having the results in a survey made it easy for me to create a workflow
        to automatically process the results in a KNIME workflow.
      </p>
      <img className="case-study-image" src={DQS4} alt={"Workflow"} />
      <p>
        These results were then shown on Tableau dashboards. The dashboard below
        was a "high level view" of workflows conducted around the enterprise.
      </p>
      <img className="case-study-image" src={DQS7} alt={"Dashboard"} />
      <p>
        Clicking into annd individual score would open details about that
        workflow.
      </p>
      <img className="case-study-image" src={DQS5} alt={"Dashboard"} />
      <p>
        Users could also open another tab to learn more about individual
        findings.
      </p>
      <img className="case-study-image" src={DQS6} alt={"Dashboard"} />

      <p>
        The method is still in use at athenahealth today as a means for quick,
        efficient usability evaluations.
      </p>
      <h4>Key Contributions</h4>

      <p>
        Led a working team in an Agile-style working group where we assessed and
        reported out on workflows on bi-weekly cadence. I managed the JIRA
        backlog of workflows, and worked with product teams to define each
        "workflow" to be assessed.
      </p>
      <p>
        I supercharged the efficiency of the method by moving the assessment of
        workflows from an in-person meeting to an asynchronous survey where
        designers could assess the workflow on their own time. This allowed
        designers to assess the workflow independently and more efficiently.
      </p>
      <p>
        I built a dashboard in Tableau and automated the analysis in KNIME in
        order to increase the efficiency of getting results to end users. (i.e.
        scrum teams)
      </p>
    </div>
  );
}
