import React from "react";
import CignaDotCom from "../Images/cignaHomePage.jpg";

export default function Cigna() {
  return (
    <div>
      <h1>Cigna.com Redesign</h1>
      <p>
        2 studies were conducted to evaluate the re-design of Cigna.com. Study 1
        was to evaluate concept flats to examine any high level issues and
        obtain user preference. Study 2 honed in on the major user flows and
        usability issues associated with them.
      </p>
      <h4>Study 1: Conceptual Flats</h4>
      <p>
        We conducted a usability test for 3 alternative home designs comparing
        constructs such as usability, time on task, and user preference.
      </p>
      <ul>
        <li>
          Tasks were created around the most frequent user paths on Cigna.com.
        </li>
        <li>
          9 participants were tested across three designs. Designs were
          presented in a different order for each participant to reduce order
          and learning effects.
        </li>
        <li>
          Based on usability data and preference information, the design below
          was chosen.
        </li>
      </ul>
      <img
        className="case-study-image"
        src={CignaDotCom}
        alt={"Cigna.com home page"}
      />
      <h4>Study 2: High Fidelity Prototypes</h4>
      <p>
        For the second study, an rapid iterative testing/evaluation (RITE)
        method was used. In a nutshell, this meant we tested the same design
        over the course of 3 days - but as issues arose, the design team adapted
        the design to better meet user needs.
      </p>
      <ul>
        <li>
          Many changes were tested - we changed button colors, locations of
          links, and interactions with certain parts of the site.
        </li>
        <li>
          At the end of testing, the results were compiled to compare all of the
          solutions tested.
        </li>
        <li>
          We analyzed the results to identify the best options that arose during
          the iterations. I worked closely with the lead designer to ensure that
          our recommendations were feasible and within scope for release.
        </li>
        <li>
          After the final readout, the design team made all of the recommended
          changes to the design.
        </li>
      </ul>
    </div>
  );
}
