import React, { useEffect }  from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";

function ReturnToTop() {
  return <Link to={"/Resume/"}>Return to top</Link>;
}
// TO DO: Indent on the small phone-friendly layout are inconsistent. Would be great if the "experience" items have the same alignment x axis as the details about your employment.
const RESEARCH_EXPERIENCE = [
  "Card Sorting",
  "Competitive Analysis",
  "Heuristic Review",
  "RITE Testing ",
  "Usability Testing",
  "Mobile Testing",
  "Summative testing",
  "Survey Design",
  "Rapid Prototyping",
  "Task Analysis",
  "Literature Reviews",
  "Statistical Analysis",
  "Linear Regression",
  "Qualitative Research",
];

const SOFTWARE_EXPERIENCE = [
  "Axure RP 7.0",
  "Sketch",
  "Figma",
  "iMovie",
  "Final Cut Pro",
  "Silverback",
  "Morae",
  "Microsoft Office Suite",
  "SPSS",
  "UserZoom",
  "UserTesting.com",
  "OptimalWorkshop",
  "KNIME",
];

const PROGRAMMING = [
  "Shell/Bash",
  "JavaScript",
  "React JS",
  "Excel Macros (VBA)",
  "HTML5/CSS",
  "Javascript",
  "R",
  "Python",
  "SQL",
];

function SkillMatrix({ skillArray, small, medium, large }) {
  skillArray = skillArray.sort();
  return (
    <Container>
      <Row>
        {skillArray.map((skillArray) => {
          return (
            <Col key={skillArray} sm={small} md={medium} lg={large}>
              {skillArray}
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}

export default function Resume() {

  useEffect(() => { 
    document.title='Will Benson | Resume'
  })
  return (
    <div className="content-wrapper">
      <h1>Resume</h1>
      <h2>William Benson</h2>
      <div>
      User Experience Researcher with over a decade of experience in both quantitative and qualitative methodologies, specializing in translating complex data into actionable insights. Proven expertise in survey design, statistical analysis, usability testing (both moderated and unmoderated), customer interviews, and heuristic evaluations. Passion for turning ambiguous data into insights that drive business success and improved customer experiences. Outside of work, Will enjoys making music, skiing, cooking, gaming, and gardening with his wife and two daughters.
      </div>
      <div>
        <h4>Research Experience</h4>
        <SkillMatrix skillArray={RESEARCH_EXPERIENCE} small={6} medium={4} large={4}/>
      </div>
      <div>
        <h4>Software Experience</h4>
        <SkillMatrix skillArray={SOFTWARE_EXPERIENCE} small={6} medium={4} large={4} />
      </div>
      <div>
        <h4>Programming Experience</h4>
        <SkillMatrix skillArray={PROGRAMMING} small={6} medium={4} large={4} />
      </div>
      <h3>EMPLOYMENT HISTORY</h3>

      <h3>Senior Experimentation Researcher, Toast</h3>
      <i>Boston, MA, April 2022 - Current</i>
      <ul>
        <li>Led over 15 surveys across multiple business areas to evaluate concept design effectiveness, analyze core server job satisfaction, assess new features, and measure current product pain points.</li>
        <li>Executed several strategic data analysis projects using SQL and Python. Collaborated with cross-functional partners to understand SQL data sources. Presented findings to leadership through async videos and automated dashboards.</li>
        <li>Spearheaded the quarterly reporting of ~20 product NPS surveys across multiple lines of business. Leveraged Python, SQL, and R to automate reporting and ensure clear, consistent data visualization.</li>
        <li>Coached and mentored cross-functional teams to implement product measurement using the Google HEART framework. Created enablement materials to help teams develop metrics. Built scorecards and dashboards to roll up reporting to senior leaders. Although the initiative was later deprioritized due to mixed adoption, it laid the groundwork for an improved measurement strategy moving forward.</li>
      </ul>

      <h3>User Research Principal, athenahealth</h3>
      <i>Watertown, MA, April 2015 - April 2022</i>
      <ul>
        <li>Supported over 100 research studies through coaching, enablement, and consulting. Curated playbooks for methods such as survey design, heuristic reviews, web tracking, and customer interviewing. Built and maintained Tableau dashboards and data pipelines to increase our ability to scale and democratize research.</li>
        <li>Headed an 8-person team in conducting 55+ heuristic reviews on athenaNet's most-used workflows. Presented findings to senior leadership to inform product priorities. Optimized the method and assessed over 100 total tasks.</li>
        <li>Championed a 5-person team in the monthly reporting of a User NPS survey. Wrote an R script to automate analysis. Conducted linear regression to determine statistical relationships between NPS and user/practice variables.</li>
        <li>Conducted end-to-end research studies in an Agile/Scrum environment leveraging 1x1 phone interviews, ethnographic research, surveys, task analysis, and web analytics.</li>
      </ul>

      <h3>User Researcher, Fidelity Investments</h3>
      <i>Boston, MA, October 2014 - March 2015</i>
      <ul>
        <li>
          Performed research to support the design team on a variety of
          products. Tested across numerous form factors including PC, mobile,
          and tablet.
        </li>
        <li>
          Research efforts include a lab study on the mobile app stock quote
          page, a UserZoom study on the Fidelity.com news page, and a
          UserTesting.com study on the password reset flow on Fidelity.com.
        </li>
        <li>
          Participated in all stages of research including recruitment of
          participants, designing a research plan, conducting research, data
          analysis, and sharing results.
        </li>
      </ul>
      <h3>Usability Analyst, Cigna </h3>
      <i>Bloomfield, CT, August 2012 - September 2014</i>
      <ul>
        <li>
          Performed over 90 research studies on 27 products and services. Was
          involved in testing re-designs of Cigna.com, a re-design of the "Find
          a Doctor" search function, user understanding of accounts ledgers on
          myCigna.com, and a re-design of a tool to compare costs of brand vs.
          generic drugs.
        </li>
        <li>
          Played a large role on a high-impact project which involved over 30
          hours of testing. Results were presented to key shareholders including
          Cigna's CMO.
        </li>
        <li>
          Modified and improved functionality of a detailed project log to
          improve accountability across the company.
        </li>
        <li>
          Developed a number of video compilations of participants to compliment
          research findings.
        </li>
      </ul>
      {/* <h3>Graduate Research Assistant, George Mason University</h3>
      Fairfax, VA, September 2010 - May 2012
      <ul>
        <li>
          Conducted Federal Aviation Administration (FAA) research to examine
          the benefits and drawbacks of the implementation of Next Generation
          (NextGen) Avionics.
        </li>
        <li>
          Helped develop the research plan, and assisted in testing over 30
          pilots on a simulation to evaluate pilot communication while using
          NextGen.
        </li>
        <li>
          Assisted in developing literature reviews, conducting task analyses
          using NGOMSL modeling, and played a large role in reducing researcher
          workload by writing macros to organize raw research data.
        </li>
      </ul> */}
      {/* <h3>
        Usability Class Project, California State University at Northridge
      </h3>
      Northridge, CA, November 2011 - May 2012
      <ul>
        <li>
          Collaborated with a small team at George Mason University to meet a
          client's needs to design a research program.
        </li>
        <li>
          Conducted iterative usability testing on mid-fidelity PowerPoint and
          Flash prototypes.
        </li>
      </ul> */}
      <div>
        <h3>EDUCATION</h3>
        <div className="education-flexbox">
          <div>
            M.A. in Human Factors/Applied Psychology
            <br />
            Received in May 2012 <br />
            George Mason University, Fairfax, VA
          </div>
          <div>
            B.S. in Psychology
            <br />
            Received in May 2010
            <br />
            SUNY College at Oneonta, Oneonta, NY
          </div>
        </div>
      </div>
      <ReturnToTop />
    </div>
  );
}
