import React from "react";
import FidelityBaselineImage from "../Images/news_screenshot.JPG";

export default function Fidelity() {
  return (
    <div className="content-wrapper">
      <h1>Fidelity News Re-Design</h1>
      <h4>Purpose</h4>
      <p>
        I worked on a research project to test a re-design of the Fidelity news
        page. The re-design was going to allow developers and writers more
        flexibility in adding/removing content on the page. Below, you can see a
        copy of the current News page. Due to legal reasons, I cannot currently
        share the designs that the team worked on.
      </p>
      <p>
        In order to ensure that the customer experience wasn't significantly
        affected, I conducted a study to answer the following questions: What
        are our customer's preferences and behaviors when viewing the new
        designs? Which of the three designs tested do customers prefer? Are
        customers familiar with certain specialty articles such as "Insights"
        and "Viewpoints?" (Note: These are Fidelity-written articles, often
        opinion-based.)
      </p>
      <img
        className="case-study-image"
        src={FidelityBaselineImage}
        alt={"Fidelity.com news webpage"}
      />
      <h4>Process</h4>
      <p>
        Participants were recruited through an existing panel of Fidelity
        customers, whom many of which were skilled investors.
      </p>

      <p>
        In order to ask them the questions I was interested in answering, I used
        UserZoom to create an online un-moderated study.
      </p>

      <p>
        Each participant was tested on only one of the three designs, and was
        asked to choose their preference for one of the designs at the end of
        the study. Other questions consisted of:
      </p>
      <ul>
        <li>Overall preference of 3 designs</li>
        <li>
          First click tasks for specific articles (e.g. TSLA is up today, where
          would you find out more?)
        </li>
        <li>
          Articles on the designs that were interesting/disinteresting to them
        </li>
        <li>
          Open-ended questions about the of Viewpoints/Insights articles (to
          determine understanding)
        </li>
      </ul>
      <h4>Results/Key Findings</h4>
      <ul>
        <li>A clear preference was determined for one of the three designs.</li>
        <li>
          It is widely accepted in the user research field that users tend to
          read in an "F-shaped" patten (source - will open a new page). Our
          results were consistent with this pattern. Participant success rates
          were worse in locations that fell outside the "F" (lower on the page,
          to the right) than articles that fell within the page (higher on the
          page, or on the left).
        </li>
        <li>
          Certain articles were uninteresting to investors and the team is going
          to use this knowledge to better determine content layout on the page.
        </li>
        <li>
          "Insights" and "Viewpoints" were clear to participants, and they saw
          them as separate articles from their regular news.
        </li>
      </ul>
    </div>
  );
}
