import React from "react";
import ReactGA from "react-ga4";
import "./App.css";
import { NavLink } from "react-router-dom";

export default function AppBar() {
  
  const handleClick = (buttonLabel) => {
    ReactGA.event({
      category: 'User interaction',
      action: 'button clicked yay', 
      label: {buttonLabel}
    });
  };

  return (
    <div>
      <div className="app-bar">
        <NavLink to="/" className="nav-item" onClick={handleClick('home')}>
          Home
        </NavLink>
        <NavLink to="/Resume" className="nav-item" onClick={handleClick('resume')}>
          Resume
        </NavLink>
        <NavLink to="/Work" className="nav-item" onClick={handleClick('work')}>
          Case Studies
        </NavLink>
        {/* <NavLink to="/Contact" className="nav-item">
          Contact
        </NavLink> */}
      </div>
    </div>
  );
}
