import React from "react";
import { Link } from "react-router-dom";

// Case Studies
import DataRec from "./DataRec";
import Workflows from "./Workflows";
import UserNPS from "./UserNPS";
import StudyCentral from "./StudyCentral";
import Fidelity from "./Fidelity";
import Cigna from "./Cigna";
import UdsUns from "./UdsUns";

function WorkWrapper({ content }) {
  function WorkHomeButton() {
    return <Link to={"/Work/"}>{"<< Go back"}</Link>;
  }
  return (
    <div className="work-page-wrapper">
      <div className="content-wrapper">
        <WorkHomeButton />
        {content}
        <WorkHomeButton />
      </div>
    </div>
  );
}

export function UdsUnsWrapped() {
  return <WorkWrapper content={UdsUns()} />;
}

export function DataRecWrapped() {
  return <WorkWrapper content={DataRec()} />;
}

export function WorkflowsWrapped() {
  return <WorkWrapper content={Workflows()} />;
}

export function UserNPSWrapped() {
  return <WorkWrapper content={UserNPS()} />;
}

export function StudyCentralWrapped() {
  return <WorkWrapper content={StudyCentral()} />;
}

export function FidelityWrapped() {
  return <WorkWrapper content={Fidelity()} />;
}

export function CignaWrapped() {
  return <WorkWrapper content={Cigna()} />;
}
