import React, { useEffect } from "react";
import photo from "../src//Images/will-benson-headshot.jpg";
import WorkLinks from "./WorkLinks";

export default function Home() {

  useEffect(() => {
    document.title="Will Benson | Portfolio"
  })
  
  return (
    <div className="content-wrapper">
      <div className="home-flexbox">
        <div className="home-image-pane">
          <h1>Hello!</h1>
          <img src={photo} alt="Photograph of Will" className="photo" />
        </div>
        <div className="home-content-pane">
          <p>
            <strong>My name is Will.</strong>
          </p>{" "}
          <p>
            I am a researcher with over a decade of experience in uncovering user
            insights.
          </p>
          <p>
            I specialize in user research, survey design, moderated interviews,
            heuristic reviews, and data analysis. 
          </p>
          <p>
            I build prototypes using Axure and Sketch, and web experiences using
            JavaScript and React. In my free time I enjoy skiing, kayaking,
            biking, running, and playing guitar.
          </p>
          <h3>Skills</h3>
          <ul>
            <li>
              <strong>User Research:</strong> I have extensive experience
              executing research and coaching teams through the process of
              conducting their own research. My specialty is in using statistics
              to help to help teams move quickly and confidently.
            </li>
            <li>
              <strong>Data Analysis:</strong> I have deep experience analyzing
              data obtained through surveys, usage analytics, qualitative
              feedback, or through database transactions. I do my best work
              using a combination of Excel, R, SQL, KNIME, and Tableau.
            </li>
            <li>
              <strong>Scaling Research with Automation:</strong> I am always
              seeking new tools to minimize time to insights and inspiration. I
              have extensive experience automating data analysis using tools
              such as R, KNIME, and my first love: Microsoft Excel.
            </li>
            <li>
              <strong>Design:</strong> I use Sketch, Axure, and Figma to rapidly
              prototype my designs.
            </li>
            <li>
              <strong>Front-End Development:</strong> I use HTML, React JS, and
              JavaScript to build usable and useful web experiences.
            </li>
          </ul>
          <h3>Case Studies</h3><br />
          <WorkLinks />
        </div>
      </div>
    </div>
  );
}
