import React from "react";
import SC1 from "../Images/SC-1.png";
import SC2 from "../Images/SC-2.png";
import SC3 from "../Images/SC-3.png";
import SC4 from "../Images/SC-4.png";
import SC5 from "../Images/SC-5.png";
import SC6 from "../Images/SC-6.png";
import SC7 from "../Images/SC-7.png";
import SC9 from "../Images/SC-9.png";
import SC10 from "../Images/SC-10.png";
import SC11 from "../Images/SC-11.png";
import SC12 from "../Images/SC-12.png";

export default function DataRec() {
  return (
    <div>
      <h1>Study Central</h1>
      <h4>What</h4>
      <p>
        I worked on the design, research, and development of an internal
        application that allows product owners and experience designers to
        request help conducting research, by enabling them to leverage
        best-practice templates, tools, as well as get connected to a panel of
        athenaNet end-users.
      </p>
      <h4>Why</h4>
      <p>
        Study Central is a tool used by the athenahealth DesignOps team to
        accelerate the process of coordinating research at athena. To help our
        small DesignOps team manage the dozens of requests that would come in on
        a weekly basis, we developed a React app to essentially act as a request
        form to help us triage research projects to different members of the
        DesignOps team.
      </p>
      <h4>How</h4>
      <p>
        The project was spurred by a need to sunset and replace an existing tool
        at athena. The tool was used to manage research requests from around the
        R&D organization - and many of our mission-critical workflows were
        depending on this being available to our team.{" "}
      </p>
      <p>
        After conducting a thorough review of alternatives, such as using a
        survey, or Microsoft PowerApps, we decided that it was best to build our
        own tool using ReactJS.
      </p>
      <h5>Auditing the current experience</h5>
      <p>
        Before we started to re-build the request form, we conducted a heuristic
        review of our current request form experience to learn what was working
        and what was not. We learned a lot about the issues that were causing
        users frustration when requesting research studies, so we took a step
        back to learn more about our users mental models when conducting
        research.
      </p>
      <p>
        Next, we wanted to check that our current understanding of our team
        thought about user research mapped back to others around the
        organization. We conducted a card sort to ensure our stakeholders
        aligned with what the major "phases" of research are. This step gave our
        team a clear taxonomy of how to communicate what the different stages of
        "research" are.
      </p>
      <p>
        Below is one of the standard outputs of OptimalWorkshop, the tool we
        used to conduct the card sort. This is a similarity matrix - and can be
        used to identify connection between "cards" in order to identify common
        groupings.
      </p>
      <img
        className="case-study-image"
        src={SC1}
        alt={"Similarity matrix output from Optimal Workshop"}
      />
      <p>
        Another visualiation we leveraged from the OptimalWorkshop platform is a
        dendogram, which helped us see the same information as above, but in a
        different visual. Cards were displayed on the far left of the
        visualization, and the dendogram would create "branches" that connected
        like cards together. The closer the branches to the left side of the
        denogram, the stronger the connection.
      </p>
      <img
        className="case-study-image"
        src={SC2}
        alt={"Dendogram output from Optimal workshop."}
      />
      <p>
        After we had this taxonomy, we ran a condensed Google Sprint over the
        course of 3-4 days. We used this workshop activity to hone in on what we
        wanted the request form platform to be. How would it feel, how users
        would move through the workflow, and how it would help DesignOps? The
        exercise led us to a clear set of prototypes that we could evaluate with
        users.
      </p>
      <p>
        Below are a few examples of storyboards that I created as part of this
        accelerator.
      </p>
      <p>
        This sketch was about how we might market relevant research methods to
        users...
      </p>
      <img className="case-study-image" src={SC3} alt={"Sketch"} />
      <p>
        This sketch described a "research assistant" which would serve as a
        "wizard" to help teams pick out a research method.
      </p>
      <img className="case-study-image" src={SC5} alt={"Sketch"} />
      <p>
        In this last sketch, I suggested an implementation of Study Central to
        Microsoft Teams.
      </p>
      <img className="case-study-image" src={SC4} alt={"Sketch"} />
      <p>
        After we sketched some initial designs, we tested a few early concepts
        with a few users to learn about what would work, and what would be
        feasible.
      </p>
      <p>
        I worked with the developers and engineers on our team to help determine
        a path forward.
      </p>
      <p>
        Once we had a more certain path, I started to work on some early
        designs.
      </p>
      <h4>Design</h4>
      <p>Here are some of the exampples of my early designs.</p>
      <p>
        Users would start on a "list" of research studies conducted at the
        organiziation...
      </p>
      <img className="case-study-image" src={SC9} alt={"Design"} />
      <p>
        Users could create new studies, where they could provide details about
        their study...{" "}
      </p>
      <img className="case-study-image" src={SC7} alt={"Design"} />
      {/* <img className="case-study-image" src={SC8} alt={"Design"} /> */}
      <p>
        And then they could submit the study to DesignOps for review and
        recruit.
      </p>
      <img className="case-study-image" src={SC6} alt={"Design"} />

      <p>
        After we finsihed designs, we did some 1:1 interviews with users around
        the organization to test understandability of the general UI components.
        Overall the site tested well, so we decided to move on and beging
        development.{" "}
      </p>
      <h4>Developement</h4>
      <p>
        Below are some images of the site as it was developed in React. While I
        assisted with the development of the form, I worked with an outstanding
        team of engineers who helped with most of the heavy lifting of this
        application.
      </p>
      <p>
        Here is how the "Study list" turned out. It was similar to the design
        spec, with a few minor differences due to technical limitations.
      </p>
      <img className="case-study-image" src={SC10} alt={"Design"} />
      <p>The request form also stayed fairly similar to the design spec:</p>
      <img className="case-study-image" src={SC11} alt={"Design"} />
      <p>As did the Review/Confirmation pages.</p>
      <img className="case-study-image" src={SC12} alt={"Design"} />
      <h4>Outcomes</h4>
      <p>
        Study Central provided to be an enormous accelerator for our team in
        supporting and triaging research from around the enterprise. With this
        tool we supported hundreds of research requests over a short couple of
        years, and created a "hub" for users to find research studies, add
        insights, and continue research efforts.
      </p>
      <h4>Key Contributions</h4>
      <ul>
        <li>
          Conducted initial foundational research interviews to gather
          requirements
        </li>
        <li>Conducted heuristic reviews to diagnose key usability issues</li>
        <li>Led the initial design of the application redesign. </li>
        <li>Front end development of the app in React JS. </li>
        <li>
          Maintained the consistent upkeep of the application and managed the
          continuous deployment of the app to keep the app up to date.{" "}
        </li>
      </ul>
    </div>
  );
}
